import Cookies from "js-cookie";
import { SimTypes } from "../../features/checkout/Types";
import EnvConfig from "../../config/env.config.json";
export const shopWareRedirectionHandler = (
  navigationPath: string,
  endPoint: string
) => {
  let swContextToken: any = Cookies.get("swContextToken");
  let token_id = localStorage.getItem("accessToken");
  let idTokenValue: any = localStorage.getItem("ssoAccessToken");
  let ssoCustomerInfo: any = localStorage.getItem("ssoCustomerDetails");
  ssoCustomerInfo = JSON.parse(ssoCustomerInfo);
  if (idTokenValue) {
    swContextToken
      ? window.location.assign(
        `${navigationPath}/${endPoint}/?token=${swContextToken}&idToken=${ssoCustomerInfo?.uaid}&ssoAccessToken=${token_id}&logged_in=${
            ssoCustomerInfo && ssoCustomerInfo.email ? true : false
          }`
        )
      : window.location.assign(
        `${navigationPath}/${endPoint}/?idToken=${ssoCustomerInfo?.uaid}&ssoAccessToken=${token_id}&logged_in=${
          ssoCustomerInfo && ssoCustomerInfo.email ? true : false
        }`
        );
  } else {
    swContextToken
      ? window.location.assign(
          `${navigationPath}/${endPoint}/?token=${swContextToken}&logged_in=${
            ssoCustomerInfo && ssoCustomerInfo.email ? true : false
          }`
        )
      : window.location.assign(
          `${navigationPath}/${endPoint}/?logged_in=${
            ssoCustomerInfo && ssoCustomerInfo.email ? true : false
          }`
        );
  }
};

const findStateByCode = (code: string, statesFromShopware:any) => {
  let stateSelected: any = statesFromShopware.payload.data.find(
    (ele: any) => {
     return ele.attributes?.shortCode?.toLowerCase()?.replace('us-', '') === code.toLowerCase()
     }
  );
  return stateSelected?.attributes?.name;
};

export const shopwareAuthentication = (statesFromShopware?:any) => {
  // let idTokenValue: any = localStorage.getItem("accessToken");
  // Need to write a check with jwt token
  let ssoToken = localStorage.getItem("ssoAccessToken")
  let currentUser: any = localStorage.getItem("currentuser");
  currentUser = JSON.parse(currentUser);
  let ssoCustomerInfo: any = localStorage.getItem("ssoCustomerInfo");
  ssoCustomerInfo = JSON.parse(ssoCustomerInfo);
  let billingDetails: any = {
    salutation: "Mr",
    firstName: ssoCustomerInfo?.first_name,
    lastName: ssoCustomerInfo?.last_name,
    zipcode: ssoCustomerInfo?.addresses[1]?.zip_code,
    city: ssoCustomerInfo?.addresses[1]?.city,
    company: "",
    street: ssoCustomerInfo?.addresses[1]?.street_address1,
    department: "",
    title: "",
    phoneNumber: ssoCustomerInfo?.phone_number,
    additionalAddressLine1: "",
    additionalAddressLine2: "",
    country: "USA",
    countryState: findStateByCode(ssoCustomerInfo?.addresses[1]?.state, statesFromShopware),
  };
  let shippingDetails: any = {
    salutation: "Mr",
    firstName: ssoCustomerInfo?.first_name,
    lastName: ssoCustomerInfo?.last_name,
    zipcode: ssoCustomerInfo?.addresses[0]?.zip_code,
    city: ssoCustomerInfo?.addresses[0]?.city,
    company: "",
    street: ssoCustomerInfo?.addresses[0]?.street_address1,
    department: "",
    title: "",
    phoneNumber: ssoCustomerInfo?.phone_number,
    additionalAddressLine1: "",
    additionalAddressLine2: "",
    country: "USA",
    countryState: findStateByCode(ssoCustomerInfo?.addresses[0]?.state, statesFromShopware),
  };
  let payload: any = {
    data: {
      registerCustomer: true,
      email: ssoCustomerInfo?.email_address,
      customerDetails: {
        salutation: "Mr",
        firstName: ssoCustomerInfo?.first_name,
        lastName: ssoCustomerInfo?.last_name,
        billingAddress: billingDetails && billingDetails,
        shippingAddress: shippingDetails && shippingDetails,
        birthdayDay: 14,
        birthdayMonth: 8,
        birthdayYear: 1947,
        title: "",
      },
      brandUrl: EnvConfig?.SHOP_DOMAIN,
    },
  };
  if (ssoToken) return payload;
};

export const updateCartPayload = () => {
  let lineItemsInCart: any = localStorage.getItem("lineItems");
  lineItemsInCart = JSON.parse(lineItemsInCart);
  let simPreferencePayload: any = localStorage.getItem("simPreference");
  simPreferencePayload = JSON.parse(simPreferencePayload);
  const linePreferenceData: any = {};
  simPreferencePayload &&
    simPreferencePayload.map((data: any, index: any) => {
      linePreferenceData["line" + (index + 1)] = {
        imei: data?.imei?.toString(),
        simPreference:
          data?.value === SimTypes.eSim ? SimTypes.ESIM : SimTypes.phySim,
        make: data?.make,
        model: data?.model,
      };
    });
  let productNumberWithPlanSelection =
    lineItemsInCart &&
    lineItemsInCart.find((data: any) =>
      data?.payload?.productNumber.match("DATA-")
    );
  let payload: any;
  if (productNumberWithPlanSelection) {
    let line: any = localStorage.getItem("reachSelectedPlanLine");
    line = JSON.parse(line);
    payload = {
      items: [
        {
          id: productNumberWithPlanSelection.id,
          payload: {
            quantity: line && parseInt(line),
            linePreferences: linePreferenceData,
          },
        },
      ],
    };
  }
  return payload;
};

export const checkUserExist = () => {
  let currentuser: any = localStorage.getItem("currentuser");
  currentuser = JSON.parse(currentuser);
  if (currentuser?.email) {
    return true;
  }
};
